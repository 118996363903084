import React, { useEffect, useState } from 'react';
import ClassNames from 'classnames';
import { useRouter } from 'next/router';
import PopupDialogBox from '../../packages/popup-dialog-box';
import LoginAndResetPassword from '../../Desktop/Common/LoginAndResetPassword';
import { MYACCOUNT_HOMEBAZAAR } from '../../../config/index';
import useAccessToken from '../../packages/use-access-token';
import WishlistPopover from '../../Desktop/Common/Wishlist/WishlistPopover';
import useUserInfo from '../../packages/use-user-info';
import classes from './v2mobileFooter.module.scss';

const StickyMobileFooter = props => {
  const {
    onSearchClick,
    count,
    city,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    addwishlisttrigger,
    wishlistpropertydeletedcall,
  } = props;
  const [is_login_open, setIsLoginOpen] = useState(false);
  const [accessToken] = useAccessToken();
  const [user] = useUserInfo();

  function closeLoginModal() {
    setIsLoginOpen(false);
  }
  const [wishlistCount, setwishlistcount] = useState(0);

  const showWishlistCount = showwishlistCount => {
    setwishlistcount(showwishlistCount);
  };
  const [anchorElwishlist, setAnchorElwishlist] = React.useState(null);

  const handleClose = () => {
    setAnchorElwishlist(null);
  };
  const router = useRouter();
  const [isNavListOpened, setIsNavListOpened] = useState(false);
  const [postPropertyRoute, setpostPropertyRoute] = useState('/post-property');
  useEffect(() => {
    if (accessToken) {
      setpostPropertyRoute(
        MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'properties' + '/add'
      );
    }
  }, [accessToken]);

  function onPlusIconClick() {
    setIsNavListOpened(!isNavListOpened);
  }

  function onNavItemClick(type) {
    switch (type) {
      case 'home':
        router.push('/');
        break;

      case 'search':
        if (onSearchClick && typeof onSearchClick === 'function')
          onSearchClick();
        break;

      case 'shortlist':
        // Router.reload('/shortlisted-properties')
        router.push('/shortlisted-properties');
        break;

      case 'buy-resale':
        router.push('/buy-a-property');
        break;

      case 'list-my-property':
        router.push('/post-property');
        break;

      case 'rent':
        router.push('/rent-a-property');
        break;

      case 'thankyou':
        router.push('/jobs-thank-you');
        break;

      case 'buy-new':
        router.push('/buy-a-property');
        break;

      case 'account':
        if (accessToken) {
          router.push(MYACCOUNT_HOMEBAZAAR);
        } else {
          setIsLoginOpen(true);
        }
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className={classes.topGap}></div>
      {isNavListOpened && (
        <div className={classes.navListContainer}>
          <button>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a target="_blank" href="/buy-a-property">
              I want to <span className="black-text">Buy New</span>
            </a>
          </button>
          <button>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a target="_blank" href="/buy-a-property">
              I want to <span className="black-text">Buy Resale</span>
            </a>
          </button>
          <button>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a target="_blank" href={postPropertyRoute}>
              I want to <span className="black-text">List my Property</span>
            </a>
          </button>
          <button>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a target="_blank" href="/rent-a-property">
              I want to <span className="black-text">Rent</span>
            </a>
          </button>
        </div>
      )}

      <section
        className={ClassNames([
          classes.root,
          isNavListOpened ? null : 'bg-mob-section',
        ])}>
        {isNavListOpened ? (
          <div className={classes.centerWhite}></div>
        ) : (
          <div className={classes.centerWhiteNormal}></div>
        )}

        <div className="mob-footer-left">
          <div className={ClassNames([classes.imgContainer])}>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            {/* <a target="_blank" href="/"> */}
            <a href={router.asPath == '/' ? '#' : '/'}>
              {/* <img
                src="/static/images/mobile/bottom-footer-home.svg"
                title="Home"
                alt="home"
                className="main"
                height="30px"
                width="30px"
              /> */}

              <img
                src="/static/images/home-icons.svg"
                title="Home"
                alt="Home"
                style={{
                  objectPosition: '-275px center',
                  objectFit: 'cover',
                  width: '21px',
                  height: '18px',
                }}
              />
            </a>
            <label style={{ marginTop: '-3px', fontSize: '11px' }}>Home</label>
          </div>
          <div
            className={ClassNames([
              classes.imgContainer,
              isNavListOpened && 'right-rounded',
            ])}
            onClick={() => onNavItemClick('search')}>
            {/* <img
              src="/static/images/mobile/footer-search-mob.png"
              title="Search"
              alt="search"
              className="main"
              height="30px"
              width="25px"
            /> */}
            <img
              src="/static/images/home-icons.svg"
              title="Search"
              alt="Search"
              style={{
                objectPosition: '-330px 0px',
                objectFit: 'cover',
                width: '20px',
                height: '20px',
              }}
            />
            <label style={{ fontSize: '11px' }}>Search</label>
          </div>
        </div>

        <div className={classes.addBtn}>
          <div className={classes.addIconContainer}>
            <div className={classes.addIconWrapper} onClick={onPlusIconClick}>
              {/* <img
                src={
                  isNavListOpened
                    ? '/static/images/mobile/bottom-footer-close.svg'
                    : '/static/images/mobile/bottom-footer-add.svg'
                }
                height={isNavListOpened ? '22px' : '25px'}
                width={isNavListOpened ? '22px' : '25px'}
                title="Favourite"
                alt="favourite"
              /> */}

              <img
                src="/static/images/home-icons.svg"
                // src="/static/images/home-icons.svg"
                title="favourite"
                alt="favourite"
                height={isNavListOpened ? '30px' : '26px'}
                width={isNavListOpened ? '30px' : '26px'}
                style={{
                  objectPosition: isNavListOpened ? '-801px 2px' : '-519px 2px',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
        </div>
        <div className="mob-footer-right">
          <div
            className={ClassNames([
              classes.imgContainer,
              isNavListOpened && 'left-rounded',
            ])}
            // onClick={() => onNavItemClick('shortlist')}
          >
            <a href="/shortlisted-properties" target="_blank">
              <div
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <span
                  style={{
                    color: 'white',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '18px',
                    // left: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '999',
                  }}>
                  {wishlistCount}
                </span>

                <img
                  src="/static/images/home-icons.svg"
                  title="Shortlist"
                  alt="Shortlist"
                  style={{
                    objectPosition: '-389px 0px',
                    objectFit: 'cover',
                    width: '22px',
                    height: '22px',
                    marginBottom: '-6px',
                  }}
                />
              </div>
              <label style={{ fontSize: '11px' }}>Shortlist</label>
            </a>
          </div>

          <div
            className={classes.imgContainer}
            onClick={() => onNavItemClick('account')}>
            {/* <img
              src="/static/images/mobile/bottom-footer-account.svg"
              title="Account"
              alt="account"
              className="main"
              height="30px"
              width="30px"
            /> */}

            <img
              src="/static/images/home-icons.svg"
              title="Account"
              alt="account"
              style={{
                objectPosition: '-378px 1px',
                objectFit: 'cover',
                width: '20px',
                height: '20px',
              }}
            />
            <label style={{ fontSize: '11px' }}>Account</label>
          </div>
        </div>
      </section>
      <PopupDialogBox
        root_class="custom-modal"
        open={is_login_open}
        close={closeLoginModal}
        modal_content={<LoginAndResetPassword closeModel={closeLoginModal} />}
      />
      <WishlistPopover
        // openDrawer={openDrawer}
        anchorEl={anchorElwishlist}
        closeLoginDrawer={handleClose}
        access_token={accessToken}
        user={user}
        showWishlistCount={showWishlistCount}
        city={city}
        count={count}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistpropertydeletedcall={wishlistpropertydeletedcall}
      />
    </>
  );
};

export default StickyMobileFooter;
